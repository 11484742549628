<template>
  <div>
    <div class="tree">
      <slot></slot>
      <unit
        :unit="root"
        :root="true"
        :show-root="showRoot"
        :editable="editableUnits"
        :display="display"
        @action="action"
      />
    </div>
    <p class="error h-center">{{ serverError }}</p>

    <delete-unit-modal ref="deleteUnitModal" @changed="changed" />
    <edit-unit-modal ref="editUnitModal" :root="root" @changed="changed" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import unit from "./unit";
import {
  addTreeElemSorted,
  getTreeElem,
  subArray,
  treeElemPos,
} from "./treeFunctions";
import deleteUnitModal from "./deleteUnitModal";
import editUnitModal from "./editUnitModal";
import serverCaller from "@/components/serverCaller";
import { ServerHandler } from "@/handlers/serverHandler";
import { UnitHandler } from "@/handlers/unitHandler";
import { AdminHandler } from "@/handlers/adminHandler";
import { UserHandler } from "@/handlers/userHandler";
import { isFeatureEnabled } from "@/feature";

export default {
  name: "UnitTree",
  components: {
    editUnitModal,
    deleteUnitModal,
    unit,
  },
  mixins: [serverCaller],
  props: {
    root: {
      type: Object,
      required: true,
    },
    showRoot: Boolean,
    editableUnits: Boolean,
    licenseInfo: {
      type: Object,
      default: () => ({}),
    },
    display: {
      type: Function,
      default: () => true,
    },
  },
  computed: {
    ...mapGetters(["company", "session"]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    unitHandler() {
      return new UnitHandler(this.$store.getters, 3, this.isRestApiEnabled);
    },
    serverHandler() {
      return new ServerHandler(this.$store.getters);
    },
    adminHandler() {
      return new AdminHandler(this.$store.getters, 2, this.isRestApiEnabled);
    },
    userHandler() {
      return new UserHandler(this.$store.getters, 3, this.isRestApiEnabled);
    },
  },
  async mounted() {
    await this.fetchServerInfo();
  },
  methods: {
    async fetchServerInfo() {
      this.serverInfo = await this.serverHandler.getServerInfo(
        this.isRestApiEnabled
      );
    },
    action(e) {
      switch (e.type) {
        case "deleteUnit":
          return this.show("deleteUnitModal", e.value);
        case "editUnit":
          return this.show("editUnitModal", e.value);
        case "removeLicense":
          return this.removeLicense(e.value);
        case "addLicense":
          return this.addLicense(e.value);
        case "drop":
          return this.drop(e.value);
      }
    },
    show(modal, obj) {
      this.serverError = "";
      this.$refs[modal].show(obj);
    },
    changed() {
      this.$emit("changed");
    },
    drop(e) {
      //TODO check is to is sub element of from
      if (e.from.type === e.to.type && e.from.id === e.to.id) {
        return;
      }
      let from = treeElemPos(this.root, e.from);
      let to = treeElemPos(this.root, e.to);
      switch (e.action) {
        case "into": {
          let target = to ? getTreeElem(to) : this.root; //e.to my be root -> to is undefined
          addTreeElemSorted(
            subArray(target, e.from.type === "team" ? "teams" : "children"),
            getTreeElem(from)
          );
          if (target.id === from.parent.id) {
            from.i++;
          }
          break;
        }
        case "below":
          to.i++;
        //fall through
        case "above":
          to.parent.children.splice(to.i, 0, getTreeElem(from));
          if (from.parent.id === to.parent.id && to.i < from.i) {
            from.i++;
          }
      }
      from.array.splice(from.i, 1);
      if (e.from.type === "team") {
        this.saveTeamUnit(e.to.id, e.from.id);
      } else {
        this.saveTree();
      }
    },
    saveTree() {
      this.unitHandler.updateUnitTree(this.root).then(
        () => {
          this.$emit("changed");
        },
        (fail) => {
          console.log("error saving tree", fail);
          this.serverError = "Could not save the change. Please try again.";
        }
      );
    },
    saveTeamUnit(unitId, teamId) {
      this.serverCall(
        this.userHandler.addUserToUnit(unitId, teamId),
        () => {
          this.$emit("changed");
        },
        () => "Could not save the change. Please try again."
      );
    },
    removeLicense(team) {
      let licenseId = team.licenses[0].id;
      this.serverCall(
        this.adminHandler.deleteLicense(licenseId),
        () => {
          team.licenses = [];
          this.$emit("changed");
        },
        () => "An error has occurred while removing license."
      );
    },
    addLicense(team) {
      let expiry = this.serverInfo.isCloud
        ? this.licenseInfo.expiryDate
        : "1.1.2999";
      this.serverCall(
        this.adminHandler.addLicense(team.id, expiry),
        () => {
          this.$emit("changed");
        },
        (fail) =>
          "An error has occurred while adding license: " + fail.error || fail
      );
    },
  },
};
</script>

<style lang="scss">
.tree {
  position: relative;
  width: 640px;
  margin: 60px auto auto;
  font-size: 1.25rem;

  & * {
    box-sizing: border-box;
  }

  div {
    margin-left: 20px;
  }

  .col-1 {
    position: absolute;
    right: 5px;
  }

  .col-2 {
    position: absolute;
    right: 85px;
  }

  .col-3 {
    position: absolute;
    right: 165px;
  }

  .header {
    text-align: center;
    width: 75px;
    margin-top: 10px;

    &.high {
      margin-top: -20px;
    }
  }
}
</style>
