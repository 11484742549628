import { authAPIUrl } from "@/environment";
import { UnitService } from "@/services/unit.service";
import { RemoteCaller } from "./remoteCaller";

export class UnitHandler {
  constructor(context, apiVersion = 3, isUsingRest = false) {
    this.remote = new RemoteCaller(context, "auth", apiVersion, "units");

    this.unitService = new UnitService(authAPIUrl);
    this.isUsingRest = isUsingRest;
  }

  async getUnitsByUser() {
    if (this.isUsingRest) {
      return this.unitService.getUnitsByUser();
    }
    const response = await this.remote.call("get_units_by_user");
    return this._transform_response(response);
  }

  async getUnitTree() {
    if (this.isUsingRest) {
      return this.unitService.getUnitTree();
    }
    const response = await this.remote.call("get_tree");
    return this._transform_response(response);
  }

  async updateUnitTree(tree) {
    if (this.isUsingRest) {
      return this.unitService.updateTree(tree);
    }
    const response = await this.remote.call("update_tree", [tree]);
    return this._transform_response(response);
  }

  async deleteUnit(unitId) {
    if (this.isUsingRest) {
      return this.unitService.deleteUnit(unitId);
    }
    const response = await this.remote.call("delete_unit", [unitId]);
    return this._transform_response(response);
  }

  _transform_response(response) {
    if (typeof response === "object" && "success" in response) {
      return response;
    } else {
      return { success: true, data: response };
    }
  }
}
