import { authAPIUrl } from "@/environment";
import { LicenseService } from "@/services/license.service";
import { RemoteCaller } from "./remoteCaller";

export class AdminHandler {
  constructor(context, apiVersion = 2, isUsingRest = false) {
    this.remote = new RemoteCaller(context, "auth", apiVersion);
    this.company = context.company;
    this.licenseService = new LicenseService(authAPIUrl);
    this.isUsingRest = isUsingRest;
  }

  getLicense() {
    if (this.isUsingRest) {
      return this.licenseService.getOnPremKey();
    }
    return this.remote.call("get_license");
  }

  setLicense(license) {
    if (this.isUsingRest) {
      return this.licenseService.updateOnPremKey(license);
    }
    return this.remote.call("set_license", [license]);
  }

  addLicense(userId, expiry) {
    return this.remote.call("add_user_license", [userId, "piplanning", expiry]);
  }

  deleteLicense(licenseId) {
    return this.remote.call("remove_user_license", [licenseId]);
  }

  createUser(name, password, role, email) {
    return this.remote.call("create_user", [this.company, name, password], {
      role: role,
      email: email,
    });
  }

  renameUser(id, name) {
    return this.remote.call("rename_user", [id, name]);
  }

  resetUser(id, password) {
    return this.remote.call("reset_password", [id, password]);
  }

  deleteCompany(password) {
    return this.remote.call("delete_company", [password]);
  }
}
