<template>
  <main
    :class="['main-section', $style.profile]"
    aria-labelledby="profile-page-header"
  >
    <h2 id="profile-page-header">User Profile</h2>
    <form novalidate @submit.prevent>
      <div :class="$style['user-img-wrap']">
        <user-image v-if="user" :user="user" :is-large="true" />
        <p :class="$style['profile-text']">Profile Photo</p>
      </div>
      <div>
        <div :class="$style['username-wrap']">
          <validated-input
            id="name"
            v-model="user.name"
            :class="$style['val-input']"
            label="Name"
            readonly
          />
          <edit-button
            title="Edit Name"
            :data-testid="`edit-button-${user.id}`"
            :class="$style['edit-btn']"
            @click="showModal = true"
          >
          </edit-button>
        </div>
        <div :class="$style['input-wrap']">
          <label for="company">Company</label>
          <base-input
            id="company"
            v-model="company"
            disabled
            label="Company"
            readonly
          />
        </div>
        <div :class="$style['input-wrap']">
          <label for="role">Role</label>
          <base-input
            id="role"
            v-model="user.role"
            disabled
            label="Role"
            readonly
          />
        </div>
        <div :class="$style['input-wrap']">
          <label for="email">E-mail</label>
          <base-input
            id="email"
            v-model="user.email"
            disabled
            label="Email"
            readonly
          />
        </div>

        <div :class="$style['input-wrap']">
          <label for="preferredLanguage">Language</label>
          <base-select
            id="preferredLanguage"
            v-model="user.preferredLanguage"
            :options="preferredLanguageOptions"
            @change="updatePreferredLanguage"
          />
        </div>
      </div>

      <div v-if="!ssoOnly" :class="$style['security-wrap']">
        <h3>Security</h3>
        <p class="fc-black">
          We'll send you a link to change your password<br />
        </p>

        <base-button
          secondary
          validate-form
          type="submit"
          @click="resetPassword()"
        >
          Change Password
        </base-button>
        <p v-if="resetPasswordRequestComplete" :class="$style['request-state']">
          Your request to reset your password was successful. You should receive
          an e-mail shortly with a link to change your password.
        </p>
        <p v-if="resetPasswordServerError">
          Could not complete the request to reset your password.
        </p>
      </div>
    </form>

    <user-mapping v-if="!isObserver" ref="user-mapping" />

    <base-modal
      :is-open="showModal"
      title="Edit Name"
      description=""
      @close="showModal = false"
    >
      <validated-input
        id="name"
        v-model="user.name"
        label="Name"
        :class="$style['user-input']"
        :autofocus="true"
        placeholder="Name"
      />
      <template #actions>
        <feedback-button type="button" success-message="" @click="renameUser">
          Save
        </feedback-button>
      </template>
    </base-modal>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidatedInput from "@/components/ValidatedInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import { UserHandler } from "@/handlers/userHandler";
import { CompanyHandler } from "@/handlers/companyHandler";
import UserImage from "@/components/UserImage.vue";
import EditButton from "@/components/EditButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseModal from "@/components/BaseModal.vue";
import FeedbackButton from "@/components/FeedbackButton.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import UserMapping from "@/pages/authRequired/users/UserMapping.vue";
import { isFeatureEnabled } from "@/feature";

export default {
  name: "ProfilePage",
  components: {
    UserMapping,
    ValidatedInput,
    BaseButton,
    UserImage,
    EditButton,
    BaseInput,
    BaseModal,
    FeedbackButton,
    BaseSelect,
  },
  data() {
    return {
      user: {
        id: "",
        role: "",
        username: "",
        email: "",
        preferredLanguage: "en",
      },
      resetPasswordRequestComplete: false,
      resetPasswordServerError: "",
      ssoOnly: true,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["isObserver", "company", "session"]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    userHandler() {
      return new UserHandler(this.$store.getters, 3, this.isRestApiEnabled);
    },
    companyHandler() {
      return new CompanyHandler(this.$store.getters, 3, this.isRestApiEnabled);
    },
    preferredLanguageOptions() {
      return [
        { value: "en", label: "English" },
        { value: "es", label: "Español" },
        { value: "pt", label: "Português" },
        { value: "ja", label: "日本語" },
        { value: "fr", label: "Français" },
        { value: "de", label: "Deutsch" },
        { value: "zh", label: "简体中文" },
      ];
    },
  },
  async mounted() {
    await this.getUserProfile();

    if (this.user.role === "admin") {
      await this.fetchSSOOnly();
    }
    if (this.$route.query.mapping) {
      this.$refs["user-mapping"].$el.scrollIntoView();
    }
  },
  methods: {
    ...mapActions("toast", ["showMessage"]),

    async fetchSSOOnly() {
      this.ssoOnly = await this.companyHandler.ssoOnlyLogin();
    },

    async getUserProfile() {
      try {
        const { data: user } = await this.userHandler.getProfile();
        this.user = { ...user };
      } catch (err) {
        this.showMessage({
          message: "Failed to load user data. Please try to refresh the page.",
          type: "error",
        });
        throw err;
      }
    },

    async resetPassword() {
      this.resetPasswordServerError = "";
      try {
        await this.userHandler.requestPasswordResetForCurrentUser();
      } catch (e) {
        this.resetPasswordServerError = e.message || e;
        return;
      }
      this.resetPasswordRequestComplete = true;
      setTimeout(() => (this.resetPasswordRequestComplete = false), 5000);
    },

    async renameUser() {
      const response = await this.userHandler.renameUser(
        this.user.id,
        this.user.name
      );
      if (!response.success) {
        throw new Error(`Could not rename user: ${response.error}`);
      }
      this.showMessage({ message: "The user name has been changed." });

      this.showModal = false;
    },
    async updatePreferredLanguage() {
      const response = await this.userHandler.changePreferredLanguage(
        this.user.preferredLanguage
      );
      if (!response.success) {
        throw new Error(`Could not change user language: ${response.error}`);
      }
      this.showMessage({ message: "The language has been changed." });
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" module>
@use "@/colors.scss";
@import "@/_variables-legacy.scss";
@import "@/styles/global.scss";

.profile {
  @include global-styles;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  h3 {
    font-size: $font-large;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 80%;

    .input-wrap {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 0.5rem;
      }
    }

    input:disabled {
      color: colors.$grey-neutral-60;
      border-bottom: 3px solid gray;
      margin-bottom: 1.25rem;
    }

    label {
      font-size: $font-semi-medium;
      color: $darkGreyColor;
    }

    .user-img-wrap {
      display: flex;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .username-wrap {
      display: flex;

      .val-input {
        margin: 0 0 18px 0;
      }
    }

    .profile-text {
      margin-top: 15px;
      margin-left: 15px;
      font-weight: bold;
    }

    .security-wrap {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .request-state {
      width: 330px;
    }

    .edit-btn {
      margin-left: -40px;
      margin-bottom: -20px;
    }
  }

  .user-input {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
