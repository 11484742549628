<template>
  <div class="main-section h-column-center">
    <h2>ALM Connections</h2>
    <div class="alm-tools-wrap">
      <data-table>
        <template #default="{ align }">
          <tr>
            <th scope="col">ALM Tool</th>
            <th scope="col">Name</th>
            <th v-if="hasUnit" scope="col">Unit</th>
            <th scope="col" :class="align.center">Status</th>
            <td class="th" :class="[align.center, $style['actions-column']]">
              Edit
            </td>
            <td class="th" :class="[align.center, $style['actions-column']]">
              Action
            </td>
          </tr>
          <tr v-for="connection in connections" :key="connection.id">
            <td>{{ connection.alm_name }}</td>
            <th scope="row">{{ connection.name }}</th>
            <td v-if="hasUnit">{{ connection.unitName }}</td>
            <td>
              <div :class="$style['status-column']">
                <base-icon
                  v-if="connection.state === 'error'"
                  icon="warning"
                  role="img"
                  title="Connection to the ALM tool failed. To get more information and abillity to fix it go to Action / Events."
                  :class="$style['warning-color']"
                />
                <base-icon
                  v-else-if="connection.state === 'okay'"
                  icon="success"
                  role="img"
                  :class="$style['success-color']"
                  title="Connection is working"
                />
              </div>
            </td>

            <td :class="[align.center, $style['actions-column']]">
              <router-link
                v-slot="{ navigate }"
                :to="{
                  name: 'editAlmConnection',
                  params: {
                    connectionId: connection.id,
                    almType: connection.alm_type,
                  },
                }"
                custom
              >
                <action-button action="edit" title="Edit" @click="navigate" />
              </router-link>
            </td>
            <td :class="[align.center, $style['actions-column']]">
              <action-menu
                title="Action menu"
                :data-testid="`action-menu-${connection.name}`"
              >
                <!-- Keyboard-nav (in action-menu) handles keyboard events -->
                <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
                <li
                  tabindex="-1"
                  role="menuitem"
                  class="list-item"
                  @click="edit(connection)"
                >
                  Edit
                </li>
                <li
                  tabindex="-1"
                  role="menuitem"
                  class="list-item"
                  @click="removeModal(connection)"
                >
                  Delete
                </li>
                <li
                  tabindex="-1"
                  role="menuitem"
                  class="list-item"
                  @click="event(connection)"
                >
                  Events
                </li>
                <template
                  v-for="action in extraAlmActions(connection.alm_tool_type)"
                >
                  <li
                    :key="action.name"
                    tabindex="-1"
                    role="menuitem"
                    class="list-item"
                    @click="action.event(connection)"
                  >
                    {{ action.name }}
                  </li>
                </template>
                <!-- eslint-enable vuejs-accessibility/click-events-have-key-events -->
              </action-menu>
            </td>
          </tr>
        </template>
      </data-table>
    </div>

    <base-button @click="goNext">Add new ALM Connection</base-button>

    <delete-modal
      ref="delete"
      title="Delete ALM Connection"
      @delete="deleteConnection"
    >
      {{ toDelete.alm_name }} '{{ toDelete.name }}'
    </delete-modal>
    <reload-workspace-modal
      :is-open="Boolean(reloadingWorkspace.connectionIdToSync)"
      :done="reloadingWorkspace.isDone"
      :error-message="reloadingWorkspace.errorMessage"
      @close="reloadingWorkspace.connectionIdToSync = false"
    ></reload-workspace-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModalLegacy.vue";
import { UnitHandler } from "@/handlers/unitHandler";
import { AlmConfigurationHandler } from "@/handlers/almConfigurationHandler";
import { AlmEventHandler } from "@/handlers/almEventHandler";
import BaseButton from "@/components/BaseButton.vue";
import ActionButton from "@/components/ActionButton.vue";
import ActionMenu from "@/components/ActionMenu.vue";
import DataTable from "@/components/DataTable.vue";
import { SessionsHandler } from "@/handlers/sessionsHandler";
import BaseIcon from "@/components/icons/BaseIcon.vue";
import ReloadWorkspaceModal from "./ReloadWorkspaceModal";
import { trackEvent } from "@/analytics/track";
import { isFeatureEnabled } from "@/feature";
import {
  almConnectionsPageAddNewButtonClicked,
  almConnectionsPageSeen,
} from "@/analytics/events";

export default {
  name: "AlmConnectionsPage",
  components: {
    ReloadWorkspaceModal,
    DeleteModal,
    ActionButton,
    ActionMenu,
    BaseButton,
    DataTable,
    BaseIcon,
  },
  data() {
    return {
      reloadingWorkspace: {
        errorMessage: null,
        connectionIdToSync: false,
        isDone: false,
      },
      connections: [],
      hasUnit: false,
      toDelete: {},
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    unitHandler() {
      return new UnitHandler(this.$store.getters, 3, this.isRestApiEnabled);
    },
    almConfigurationHandler() {
      return new AlmConfigurationHandler(this.$store.getters);
    },
    sessionsHandler() {
      return new SessionsHandler(this.$store.getters);
    },
    almEventHandler() {
      return new AlmEventHandler(this.$store.getters);
    },
  },
  created() {
    this.getAlmConnections();
  },
  mounted() {
    trackEvent(almConnectionsPageSeen());
  },
  methods: {
    getAlmConnections() {
      this.almConfigurationHandler.getAlmConnections().then((cons) => {
        this.connections = cons.map((connection) => ({
          ...connection,
        }));
        this.hasUnit = cons.some((c) => !!c.unit);
        if (this.hasUnit) {
          this.unitHandler.getUnitsByUser().then(({ data: units }) => {
            this.connections.forEach((con) => {
              let unit = units.find((u) => u.id === con.unit);
              this.$set(con, "unitName", (unit && unit.name) || "");
            });
          });
        }
      });
    },
    edit(connection) {
      this.$router.push({
        name: "editAlmConnection",
        params: { connectionId: connection.id, almType: connection.alm_type },
      });
    },
    event(connection) {
      this.$router.push({
        name: "almConnectionEvents",
        params: { connectionId: connection.id, almType: connection.alm_type },
      });
    },
    removeModal(connection) {
      this.toDelete = this.$refs.delete.show(connection);
    },
    async deleteConnection(connection) {
      const almHandler = this.$almHandler({
        almTool: connection.alm_type,
        alm_connection_id: connection.id,
      });
      const res = await almHandler.delete();
      if (res.success) {
        this.$refs.delete.close();
        this.getAlmConnections();
      } else {
        this.setSessionsNames(res.sessions_ids);
      }
    },
    setSessionsNames(ids) {
      let sessionsNames = "";
      this.sessionsHandler.getSessions().then((sessions) => {
        for (let index = 0; index < ids.length; index++) {
          sessions.forEach((singleSession) => {
            if (ids[index] === singleSession.session_id) {
              sessionsNames += singleSession.name;
              if (index !== ids.length - 1) {
                sessionsNames += ", ";
              }
            }
          });
        }
        sessionsNames += ".";
        this.$refs.delete.fail(
          `There are planning sessions using this ALM: ${sessionsNames}. Delete them first.`
        );
      });
    },
    goNext() {
      trackEvent(almConnectionsPageAddNewButtonClicked());
      this.$router.push({ name: "AddAlmConnection" });
    },
    extraAlmActions(almToolType) {
      return [
        {
          name: "Reload Status and Workflows",
          event: async (connection) => {
            this.reloadingWorkspace.isDone = false;
            this.reloadingWorkspace.connectionIdToSync = connection.id;

            const { id: connectionId } = connection;
            try {
              await this.$almHandler({
                almTool: almToolType.toLowerCase(),
                alm_connection_id: connectionId,
              }).loadAlmItemTypes();
            } catch (err) {
              this.reloadingWorkspace.errorMessage = err.toString();
            } finally {
              this.reloadingWorkspace.isDone = true;
            }
          },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";
@import "@/styles/global.scss";

.main-section {
  @include global-styles;
}

.alm-tools-wrap {
  width: 100%;
  margin-bottom: 32px;

  .actions-column {
    width: 80px;
  }
}
</style>

<style lang="scss" module>
@import "@/_colors.scss";

.actions-column {
  width: 80px;
}

.status-column {
  display: flex;
  width: max-content;
  margin: 0 auto;
}

.warning-color {
  color: $icon-warning-color;
}

.success-color {
  color: $icon-success-color;
}
</style>
