import { RemoteCaller } from "./remoteCaller";

export class AlmConfigurationHandler {
  constructor(context, apiVersion = 6) {
    this.remote = new RemoteCaller(context, "piplanning", apiVersion, "alm");
  }

  getAlmConnections() {
    return this.remote.call("get_alm_connections");
  }

  getAlmTools() {
    return this.remote.call("get_alm_tools");
  }

  getAlmConfiguration(sessionId) {
    return this.remote.call("get_session_info", [sessionId]);
  }

  createAlmConnection(almType, unitId) {
    const args = unitId ? [[almType], { unit_id: unitId }] : [[almType]];

    return this.remote.call("create_alm_connection", ...args);
  }
}
