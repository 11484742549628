<template>
  <delete-modal
    ref="modal"
    title="Delete this unit"
    data-testid="delete-unit-modal"
    @delete="deleteUnit"
  >
    Are you sure you want to delete '{{ toDelete.name }}'?
  </delete-modal>
</template>

<script>
import serverCaller from "@/components/serverCaller";
import DeleteModal from "@/components/DeleteModalLegacy.vue";
import { UnitHandler } from "@/handlers/unitHandler";

export default {
  name: "DeleteUnitModal",
  components: { DeleteModal },
  mixins: [serverCaller],
  data() {
    return {
      toDelete: {},
      unitHandler: new UnitHandler(this.$store.getters),
    };
  },
  methods: {
    show(unit) {
      this.toDelete = this.$refs.modal.show(unit);
    },
    deleteUnit(unit) {
      this.serverCall(
        this.unitHandler.deleteUnit(unit.id),
        () => {
          this.$refs.modal.ok();
          this.$emit("changed");
        },
        (fail) =>
          this.$refs.modal.fail(errorMessage(fail.errorCode, fail.items))
      );

      function errorMessage(code, items) {
        let is = items
          ? items.map((item) => '"' + (item.name || item) + '"').join(", ")
          : "";
        switch (code) {
          case 1:
            return `You don't have the rights to delete this unit.`;
          case 2:
            return `This unit has these sub units: ${is}. Please delete them first.`;
          case 3:
            return `This unit has these planning sessions: ${is}. Please delete them first.`;
          case 4:
            return `This unit has these ALM connections: ${is}. Please delete them first.`;
          default:
            return "Could not delete this unit. Please try again.";
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
