import { dateToUnixTimestamp, generateID } from "@/utils";
import * as localRepository from "@/components/session/sessionLocalStorageRepository";
import LinkService, { linkRefsFromIdToAlmType } from "@/handlers/linkService";
import SessionService from "@/handlers/sessionService";
import SessionHandler from "@/handlers/sessionHandler";
import { SessionsHandler } from "@/handlers/sessionsHandler";
import { UnitHandler } from "@/handlers/unitHandler";

const settingsKeys = [
  "confirm_sticky_delete",
  "team_forbid_sticky_delete",
  "team_move_unmapped_to_backlog",
  "move_activated",
  "hide_program_backlog",
  "backlog_forbid_JIRA_sticky_delete",
  "backlog_forbid_WSJF_edit",
  "backlog_mirror_assigned_stickies_to_team_board",
  "iteration_load_warn",
  "iteration_load_critical",
];

export class SessionDuplicationService {
  constructor(
    sessionId,
    sessionInfo,
    almHandler,
    context,
    isUsingRest = false
  ) {
    this.sessionHandler = new SessionHandler(context, sessionId);
    this.sessionInfo = sessionInfo;
    this.almHandler = almHandler;
    this.context = context;
    this.isUsingRest = isUsingRest;
  }

  async duplicateSession(withIterations) {
    const sessionsHandler = new SessionsHandler(this.context);
    const unitHandler = new UnitHandler(this.context, 3, this.isUsingRest);
    const sessionService = new SessionService(sessionsHandler, unitHandler);
    const linkService = new LinkService(this.sessionHandler, this.almHandler);

    const iterations = (await this.sessionHandler.loadIterations()).map(
      (iteration) => ({
        ...iteration,
        start: withIterations ? dateToUnixTimestamp(iteration.start) : null,
        end: withIterations ? dateToUnixTimestamp(iteration.end) : null,
      })
    );
    const start = await this.sessionHandler.getStartDate();
    localRepository.setGeneralInformation({
      piPlanningName: "Duplicate of " + (await this.sessionHandler.getName()),
      unitName: await sessionService.getSessionUnitName(
        this.sessionHandler.sessionID
      ),
      startDate: !start || start.getTime() < Date.now() ? undefined : start,
      noOfIterations: iterations.length,
      durationOfOneIterationInDays:
        1 +
        Math.round((iterations[0].end - iterations[0].start) / (24 * 60 * 60)),
      almTool: this.sessionInfo.alm_connection_type || "",
      selectedConnectionID: this.sessionInfo.alm_connection_id,
      previouslySelectedAlmTool: this.sessionInfo.alm_connection_id,
      sync_session_id: this.sessionInfo.sync_session_id,
      almToolName: this.almHandler.isAlm
        ? await this.almHandler.getName()
        : "None",
    });

    const rawSettings = await this.sessionHandler.getSettings();
    localRepository.setSettings(
      Object.fromEntries(settingsKeys.map((key) => [key, rawSettings[key]]))
    );

    const teams = await this.sessionHandler.getTeams();
    teams.forEach((team) => (team.id = team.user_id));
    localStorage.removeItem("selectedArts");
    localRepository.setSelectedTeams(
      teams.filter((team) => !team.archived).map((team) => team.user_id)
    );
    localRepository.setSelectedParticipatingTeams(
      await this.sessionHandler.getParticipatingTeamIds()
    );

    const teamStickies = await this.loadBoardStickies("team");
    const riskStickies = await this.loadBoardStickies("risk");
    const backlogStickies = await this.loadBoardStickies("backlog");
    const programStickies = await this.loadBoardStickies("program");
    const solutionStickies = await this.loadBoardStickies("solution");
    const solution_backlogStickies = await this.loadBoardStickies(
      "solution_backlog"
    );
    const stickies = [
      ...teamStickies,
      ...riskStickies,
      ...backlogStickies,
      ...programStickies,
      ...solutionStickies,
      ...solution_backlogStickies,
    ];

    localRepository.setTeamStickies(teamStickies);
    localRepository.setRiskStickies(riskStickies);
    localRepository.setBacklogStickies(backlogStickies);
    localRepository.setProgramStickies(programStickies);
    localRepository.setSolutionPlanningStickies(solutionStickies);
    localRepository.setSolutionBacklogStickies(solution_backlogStickies);

    const flexBoards = await this.sessionHandler.loadFlexBoards();
    flexBoards.forEach((f) => {
      delete f.id;
      (f.stickyTypes || []).forEach((s) => delete s.id);
    });
    localRepository.setFlexBoards(flexBoards);
    // Set team field mapping
    let teamFieldMapping = {};
    if (this.sessionInfo.alm_connection_type === "jira") {
      // If duplicating a one-art jira session, copy its team field mapping
      const oldArts = await this.sessionHandler.getArts();
      const singleArtSession = oldArts.length === 1;
      teamFieldMapping = singleArtSession
        ? (await this.almHandler.getTeamFieldMappingForOneArtSession()).data
        : {};
    }
    localRepository.setTeamFieldMapping(teamFieldMapping);

    if (this.almHandler.isAlm) {
      if (this.almHandler.supportsLinks) {
        const teams = (await this.sessionHandler.getTeams()).map(
          ({ user_id, art_id }) => ({
            id: user_id,
            artId: art_id,
          })
        );
        const linkStickyTypes = await linkService.createLinkStickyTypes(
          await linkService.getStickyTypesWithAlmInfo(),
          await this.almHandler.getIssueTypes(),
          teams,
          await this.almHandler.getProjects()
        );
        const issueLinkTypes = await this.almHandler.getIssueLinkTypes();
        const links = await linkService.getLinks(
          linkStickyTypes,
          issueLinkTypes
        );
        links.forEach((l) => delete l.id);
        linkRefsFromIdToAlmType(links, stickies);
        localRepository.setLinks(links);
      }

      await this.almHandler.addTeamsToIterations(iterations, teams, true);
      if (!withIterations) {
        iterations.forEach((iter) => {
          iter.teams.forEach((team) => {
            team.sources.forEach((source) => {
              source.sprint = null;
            });
          });
        });
      }
    }
    localRepository.setIterations(iterations);
  }

  async loadBoardStickies(boardType) {
    const stickyTypes = await this.sessionHandler.loadStickyTypes(
      boardType,
      this.almHandler
    );
    return stickyTypes
      .filter((sticky) => sticky.origin_board_type === boardType)
      .map((sticky) => ({
        ...sticky,
        id: null,
        _id: sticky.id,
        frontend_id: sticky.alm_type || generateID(),
      }));
  }
}
