<template>
  <base-modal
    title="Archive session"
    description="Confirm that you want to proceed."
    data-testid="archive-modal"
    :is-open="showArchiveSessionModal"
    @close="showArchiveSessionModal = false"
  >
    Are you sure you want to archive '{{ pi_session.name }}'?

    <ul>
      <li>Archived sessions do not sync with your ALM tool anymore</li>
      <li>Archived sessions can be unarchived at any time</li>
    </ul>
    <template name="buttons">
      <feedback-button
        class="next-btn valid fs-medium"
        success-message="Archived successfully."
        @click.prevent="archiveSession()"
      >
        Archive
      </feedback-button>
    </template>
  </base-modal>
</template>

<script>
import { mapGetters } from "vuex";
import serverCaller from "@/components/serverCaller";
import SessionHandler from "@/handlers/sessionHandler";
import FeedbackButton from "@/components/FeedbackButton.vue";
import BaseModal from "@/components/BaseModal";

export default {
  name: "ArchiveSessionModal",
  components: { FeedbackButton, BaseModal },
  mixins: [serverCaller],
  props: {
    sessionID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pi_session: { name: "" },
      message: {
        text: "",
        type: "success",
      },
      showArchiveSessionModal: false,
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    sessionHandler() {
      return new SessionHandler(this.$store.getters, this.sessionID);
    },
  },
  methods: {
    show(session) {
      this.pi_session = session;
      this.showArchiveSessionModal = true;
    },
    async archiveSession() {
      this.message.text = "";
      try {
        const response = await this.sessionHandler.archiveSession(
          this.pi_session.id
        );
        this.showArchiveSessionModal = false;
        this.$emit("changed");
        return response;
      } catch (error) {
        throw new Error("Could not archive the session:" + error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin-left: 10%;
  width: 100%;
  margin-bottom: 40px;
  text-align: start;
  list-style-type: disc;
  line-height: 1.5em;
}

li {
  display: list-item;
}
</style>
