<template>
  <div class="main-section h-column-center">
    <h2 class="f-center fs-extra-large">Create new ALM connection</h2>
    <div class="row label-wrap">
      <label class="fs-semi-medium fc-black" for="select-alm-tool">
        Select ALM Tool
      </label>
    </div>
    <div class="row">
      <pi-select
        id="select-alm-tool"
        :options="availableTools"
        value-prop="value"
        :value="currentTool"
        @input="setTool"
      />
    </div>

    <div v-if="units.length > 1" class="row label-wrap">
      <label class="fs-semi-medium fc-black" for="select-unit">
        Select Unit
      </label>
    </div>
    <div v-if="units.length > 1" class="row">
      <pi-select
        id="select-unit"
        :options="units"
        value-prop="name"
        :value="currentUnit"
        @input="setUnit"
      />
    </div>
    <div class="h-column-center add-new-wrap">
      <button class="next-btn valid" @click="goNext">Next</button>
      <p>{{ serverError }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import serverCaller from "@/components/serverCaller";
import piSelect from "@/components/choose";
import { UnitHandler } from "@/handlers/unitHandler";
import { AlmConfigurationHandler } from "@/handlers/almConfigurationHandler";
import { trackEvent } from "@/analytics/track";
import { createAlmConnectionsConnectToAlmStarted } from "@/analytics/events";
import { isFeatureEnabled } from "@/feature";

export default {
  name: "AddNewAlmConnection",
  components: {
    piSelect,
  },
  mixins: [serverCaller],
  data() {
    return {
      almTools: {},
      units: [],
      selectedTool: "jira",
      selectedUnit: 0,
      expandSelectUnit: false,
      selectUnitWrapHeight: "60px",
      showNotImplemented: false,
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    currentTool() {
      return {
        key: this.selectedTool,
        value: this.almTools[this.selectedTool],
      };
    },
    availableTools() {
      return Object.entries(this.almTools).map(([key, value]) => ({
        key,
        value,
      }));
    },
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    currentUnit() {
      return this.units.find(({ id }) => this.selectedUnit === id);
    },
    unitHandler() {
      return new UnitHandler(this.$store.getters, 3, this.isRestApiEnabled);
    },
    almConfigurationHandler() {
      return new AlmConfigurationHandler(this.$store.getters);
    },
  },
  created() {
    this.almConfigurationHandler.getAlmTools().then((res) => {
      this.almTools = res;
    });
    this.unitHandler.getUnitsByUser().then(({ data: units }) => {
      this.units = units;
      if (units.length > 0) {
        this.selectedUnit = units[0].id;
      }
    });
  },
  methods: {
    setTool({ key }) {
      this.selectedTool = key;
    },
    setUnit({ id }) {
      this.selectedUnit = id;
    },
    goNext() {
      trackEvent(createAlmConnectionsConnectToAlmStarted(this.selectedTool));
      let unit = this.units.length === 0 ? undefined : this.selectedUnit;
      this.serverCall(
        this.almConfigurationHandler.createAlmConnection(
          this.selectedTool,
          unit
        ),
        (res) => {
          localStorage.setItem("isAlmConnectionJustCreated", true);
          this.$router.push({
            name: "editAlmConnection",
            params: { connectionId: res.id, almType: this.selectedTool },
          });
        },
        (fail) => fail.error || fail
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_variables-legacy.scss";

::v-deep .custom-select-wrap {
  width: 480px;
}

::v-deep .selected-option {
  color: $darkBlueColor;
  padding-left: 80px;
}

.next-btn {
  width: 300px;
}

.label-wrap {
  margin-top: 25px;
  margin-bottom: 8px;
}
</style>
